import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import useAudio from '../../hooks/use-audio'
import { Link } from 'gatsby'


const CantoPage = () => {
  useAudio("https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/procesion-cerca.mp3")

  return (
    <Layout>
      <nav class="navbar">
        <Link class="navbar-brand" to="/">Volver</Link>
      </nav>
      <SEO title="Canto" />
      <img className="img-fluid" src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-8.jpg" alt="Arbol" />
      <div className="row justify-content-center">
        <div className="col-12 col-md-8">
          <h1 className="mb-5 mt-5 text-center">Acto Canto</h1>
          <p class="sm-line-height text-center">
            woo, woo, woo
                    <br /><br />
            <br /> lek, lek, lek, lek
            <br />  <br /><br />meee, meee
            <br />  <br /><br />lee, lee, lee
                </p>
          <br />
          <p class=" text-center">
            Cantan los sapos en la ceremonia de rogación de lluvia del <em className="no-wrap">Ch´a´Cháak</em>. “ Son “los músicos de <em>Chaak</em>”. Viven con las tortugas en las cuevas, cuando empiezan a cantar es porque la tierra está muy seca, y piden agua a los Cháako´ob” <span className="no-wrap">(Terán y Rasmussen, 2008).</span>
            Son los cantos de los niños que imitan a las ranas y a los sapos para que venga la lluvia.
                </p>
          <br />
          <p class="sm-line-height text-center">
            Tooj tooj <br /><br /><br /> Canta el pájaro Toj, el guardián de los cenotes. <br /><br /><br /> Jut jut jut jut <br /><br /><br /> Yaa yaa
                    <br /><br /><br />Cantos melancólicos y alegres de las aves.
                </p>
          <br />
          <p class="text-center">
            “- He aquí que repasaremos la antigua historia del universo vacío, del universo invisible, donde existía sólo el espacio del cielo… <br /><br />En ese momento se escuchó el canto de las codornices, se escuchó el canto de los faisanes.
                    Era de noche. Entre los cantos se escuchó el fuerte rugido de un felino. Rugió como ruge el cielo en tiempo de lluvia. Rugió tres veces. El pueblo tembló. Tuvo miedo. Los niños se refugiaron en brazos de sus madres. Nadie dijo nada.
                    El rostro del Halach estaba tranquilo como siempre; de pronto, un gran meteorito iluminó el horizonte cruzando sobre ellos, luego desapareció en el espacio dividiéndose en tres partes. …
                    <br /><br />He aquí hijos míos que la hora ha llegado, nuestro destino se ha escrito en el Libro del Tiempo. Grandes sucesos nos esperan en el paso de los años.” (Dzib y Noh, 1999).
                </p>
        </div>
      </div>


    </Layout>
  )
}

export default CantoPage
